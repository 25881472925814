{
	"fr-CA": {
        "Yes": "Oui",
        "No": "Non",
        "Cancel": "Annuler",
        "Clear": "Effacer",
        "Done": "Terminé",
        "Loading": "Chargement...",
        "Select": "Sélectionner...",
        "Search": "Chercher",
        "Back": "Retour",
        "OK": "OK",

        "dxCollectionWidget-noDataText": "Aucunes données à afficher",

        "validation-required": "Requis",
        "validation-required-formatted": "{0} est requis",
        "validation-numeric": "La valeur doit être un nombre",
        "validation-numeric-formatted": "{0} doit être un nombre",
        "validation-range": "La valeur est hors de portée",
        "validation-range-formatted": "{0} est hors de portée",
        "validation-stringLength": "La longueur de la valeur n'est pas correcte",
        "validation-stringLength-formatted": "La longueur de {0} n'est pas correcte",
        "validation-custom": "Valeur invalide",
        "validation-custom-formatted": "{0} est invalide",
        "validation-compare": "Les valeurs ne correspondent pas",
        "validation-compare-formatted": "{0} ne correspond pas",
        "validation-pattern": "La valeur ne correspond pas au modèle",
        "validation-pattern-formatted": "{0} ne correspond pas au modèle",
        "validation-email": "Ce courriel est invalide",
        "validation-email-formatted": "{0} est invalide",
        "validation-mask": "La valeur est invalide",

        "dxLookup-searchPlaceholder": "Nombre de caractères minimal : {0}",

        "dxList-pullingDownText": "Tirer pour rafraîchir...",
        "dxList-pulledDownText": "Relacher pour rafraîchir...",
        "dxList-refreshingText": "Rafraîchissement...",
        "dxList-pageLoadingText": "Chargement...",
        "dxList-nextButtonText": "Plus",
        "dxList-selectAll": "Sélectionner tout",
        "dxListEditDecorator-delete": "Supprimer",
        "dxListEditDecorator-more": "Plus",

        "dxScrollView-pullingDownText": "Tirer pour rafraîchir...",
        "dxScrollView-pulledDownText": "Relâcher pour rafraîchir...",
        "dxScrollView-refreshingText": "Rafraîchissement...",
        "dxScrollView-reachBottomText": "Chargement...",

        "dxDateBox-simulatedDataPickerTitleTime": "Sélectionner l'heure",
        "dxDateBox-simulatedDataPickerTitleDate": "Sélectionner la date",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Sélectionner la date et l'heure",
        "dxDateBox-validation-datetime": "La valeur doit être une date ou une heure",

        "dxFileUploader-selectFile": "Sélectionner un fichier",
        "dxFileUploader-dropFile": "ou glisser un fichier ici",
        "dxFileUploader-bytes": "octets",
        "dxFileUploader-kb": "ko",
        "dxFileUploader-Mb": "Mo",
        "dxFileUploader-Gb": "Go",
        "dxFileUploader-upload": "Téléverser",
        "dxFileUploader-uploaded": "Téléversé",
        "dxFileUploader-readyToUpload": "Prêt à téléverser",
        "dxFileUploader-uploadFailedMessage": "Échec du téléversement",

        "dxRangeSlider-ariaFrom": "De",
        "dxRangeSlider-ariaTill": "À",

        "dxSwitch-onText": "MARCHE",
        "dxSwitch-offText": "ARRÊT",

        "dxForm-optionalMark": "optionnel",
        "dxForm-requiredMessage": "{0} est requis",

        "dxNumberBox-invalidValueMessage": "La valeur doit être un nombre",

        "dxDataGrid-columnChooserTitle": "Choisir une colonne",
        "dxDataGrid-columnChooserEmptyText": "Glisser une colonne ici pour la cacher",
        "dxDataGrid-groupContinuesMessage": "Suite à la prochaine page",
        "dxDataGrid-groupContinuedMessage": "Suite de la page précédente",
        "dxDataGrid-groupHeaderText": "Grouper à partir de ce colonne",
        "dxDataGrid-ungroupHeaderText": "Dégrouper",
        "dxDataGrid-ungroupAllText": "Tout dégrouper",
        "dxDataGrid-editingEditRow": "Modifier",
        "dxDataGrid-editingSaveRowChanges": "Sauvegarder",
        "dxDataGrid-editingCancelRowChanges": "Annuler",
        "dxDataGrid-editingDeleteRow": "Supprimer",
        "dxDataGrid-editingUndeleteRow": "Restaurer",
        "dxDataGrid-editingConfirmDeleteMessage": "Voulez-vous vraiment supprimer cet enregristrement ?",
        "dxDataGrid-validationCancelChanges": "Annuler les changements",
        "dxDataGrid-groupPanelEmptyText": "Glisser l'en-tête de la colonne pour la grouper",
        "dxDataGrid-noDataText": "Aucunes données",
        "dxDataGrid-searchPanelPlaceholder": "Recherche...",
        "dxDataGrid-filterRowShowAllText": "(Tout)",
        "dxDataGrid-filterRowResetOperationText": "Réinitialiser",
        "dxDataGrid-filterRowOperationEquals": "Égal",
        "dxDataGrid-filterRowOperationNotEquals": "N'est pas égal à",
        "dxDataGrid-filterRowOperationLess": "Moins que",
        "dxDataGrid-filterRowOperationLessOrEquals": "Moins que ou égal à",
        "dxDataGrid-filterRowOperationGreater": "Plus grand que",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Plus grand que ou égal à",
        "dxDataGrid-filterRowOperationStartsWith": "Débute par",
        "dxDataGrid-filterRowOperationContains": "Contient",
        "dxDataGrid-filterRowOperationNotContains": "Ne contient pas",
        "dxDataGrid-filterRowOperationEndsWith": "Termine par",
        "dxDataGrid-filterRowOperationBetween": "Entre",
        "dxDataGrid-filterRowOperationBetweenStartText": "Début",
        "dxDataGrid-filterRowOperationBetweenEndText": "Fin",
        "dxDataGrid-applyFilterText": "Appliquer le filtre",
        "dxDataGrid-trueText": "Vrai",
        "dxDataGrid-falseText": "Faux",
        "dxDataGrid-sortingAscendingText": "Tri croissant",
        "dxDataGrid-sortingDescendingText": "Tri décroissant",
        "dxDataGrid-sortingClearText": "Supprimer tri",
        "dxDataGrid-editingSaveAllChanges": "Sauvegarder les changements",
        "dxDataGrid-editingCancelAllChanges": "Annuler les changements",
        "dxDataGrid-editingAddRow": "Ajouter une ligne",
        "dxDataGrid-summaryMin": "Min: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Min de {1} est {0}",
        "dxDataGrid-summaryMax": "Max: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Max de {1} est {0}",
        "dxDataGrid-summaryAvg": "Avg: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Avg de {1} est {0}",
        "dxDataGrid-summarySum": "Sum: {0}",
        "dxDataGrid-summarySumOtherColumn": "Sum de {1} est {0}",
        "dxDataGrid-summaryCount": "Compte: {0}",
        "dxDataGrid-columnFixingFix": "Figer",
        "dxDataGrid-columnFixingUnfix": "Défiger",
        "dxDataGrid-columnFixingLeftPosition": "À gauche",
        "dxDataGrid-columnFixingRightPosition": "À droite",
        "dxDataGrid-exportTo": "Exporter",
        "dxDataGrid-exportToExcel": "Exporter en fichier Excel",
        "dxDataGrid-excelFormat": "Fichier Excel",
        "dxDataGrid-selectedRows": "Lignes séléctionnées",
        "dxDataGrid-exportSelectedRows": "Exporter les lignes séléctionnées",
        "dxDataGrid-exportAll": "Exporter toutes les données",
        "dxDataGrid-headerFilterEmptyValue": "(vide)",
        "dxDataGrid-headerFilterOK": "OK",
        "dxDataGrid-headerFilterCancel": "Annuler",
        "dxDataGrid-ariaColumn": "Colonne",
        "dxDataGrid-ariaValue": "Valeur",
        "dxDataGrid-ariaFilterCell": "Filtrer cellule",
        "dxDataGrid-ariaCollapse": "Réduire",
        "dxDataGrid-ariaExpand": "Développer",
        "dxDataGrid-ariaDataGrid": "Grille des données",
        "dxDataGrid-ariaSearchInGrid": "Chercher dans la grille",
        "dxDataGrid-ariaSelectAll": "Sélectionner tout",
        "dxDataGrid-ariaSelectRow": "Sélectionner une ligne",

        "dxTreeList-ariaTreeList": "Arbre",
        "dxTreeList-editingAddRowToNode": "Ajouter",

        "dxPager-infoText": "Page {0} sur {1} ({2} items)",
        "dxPager-pagesCountText": "sur",

        "dxPivotGrid-grandTotal": "Grand Total",
        "dxPivotGrid-total": "{0} Total",
        "dxPivotGrid-fieldChooserTitle": "Sélecteur de champ",
        "dxPivotGrid-showFieldChooser": "Montrer le sélecteur de champ",
        "dxPivotGrid-expandAll": "Développer tout",
        "dxPivotGrid-collapseAll": "Réduire tout",
        "dxPivotGrid-sortColumnBySummary": "Trier \"{0}\" par cette colonne",
        "dxPivotGrid-sortRowBySummary": "Trier \"{0}\" par cette ligne",
        "dxPivotGrid-removeAllSorting": "Supprimer tous les filtres",
        "dxPivotGrid-dataNotAvailable": "N/A",
        "dxPivotGrid-rowFields": "champs de ligne",
        "dxPivotGrid-columnFields": "champs de colonne",
        "dxPivotGrid-dataFields": "Champs de données",
        "dxPivotGrid-filterFields": "Filtrer les champs",
        "dxPivotGrid-allFields": "Tous les champs",
        "dxPivotGrid-columnFieldArea": "Glisser les champs des colonnes ici",
        "dxPivotGrid-dataFieldArea": "Glisser les champs des données ici",
        "dxPivotGrid-rowFieldArea": "Glisser les champs des rangées ici",
        "dxPivotGrid-filterFieldArea": "Glisser les champs de tri ici",

        "dxScheduler-editorLabelTitle": "Motif",
        "dxScheduler-editorLabelStartDate": "Date de début ",
        "dxScheduler-editorLabelEndDate": "Date de fin",
        "dxScheduler-editorLabelDescription": "Description",
        "dxScheduler-editorLabelRecurrence": "Répéter",

        "dxScheduler-openAppointment": "Ouvrir un événement",

        "dxScheduler-recurrenceNever": "Jamais",
        "dxScheduler-recurrenceDaily": "Quotidien",
        "dxScheduler-recurrenceWeekly": "Hebdomadiare",
        "dxScheduler-recurrenceMonthly": "Mensuel",
        "dxScheduler-recurrenceYearly": "Annuel",

        "dxScheduler-recurrenceEvery": "Chaque",
        "dxScheduler-recurrenceEnd": "Fin de répétition",
        "dxScheduler-recurrenceAfter": "Après",
        "dxScheduler-recurrenceOn": "Le",

        "dxScheduler-recurrenceRepeatDaily": "jour(s)",
        "dxScheduler-recurrenceRepeatWeekly": "semaine(s)",
        "dxScheduler-recurrenceRepeatMonthly": "mois",
        "dxScheduler-recurrenceRepeatYearly": "année(s)",

        "dxScheduler-switcherDay": "Jour",
        "dxScheduler-switcherWeek": "Semaine",
        "dxScheduler-switcherWorkWeek": "Semaine de travail",
        "dxScheduler-switcherMonth": "Mois",

        "dxScheduler-switcherAgenda": "Agenda",

        "dxScheduler-switcherTimelineDay": "Jour d'horaire",
        "dxScheduler-switcherTimelineWeek": "Semaine d'horaire",
        "dxScheduler-switcherTimelineWorkWeek": "Semaine ouvrable d'horaire",
        "dxScheduler-switcherTimelineMonth": "Mois d'horaire",

        "dxScheduler-recurrenceRepeatOnDate": "à la date",
        "dxScheduler-recurrenceRepeatCount": "occurrence(s)",
        "dxScheduler-allDay": "Toute la journée",

        "dxScheduler-confirmRecurrenceEditMessage": "Voulez-vous modifier seulement cet événement ou toute la série ?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Voulez-vous supprimer seulement cet événement ou toute la série ?",

        "dxScheduler-confirmRecurrenceEditSeries": "Modifier la série",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Supprimer la série",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Modifier l'événement",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Supprimer l'événement",

        "dxScheduler-noTimezoneTitle": "Aucun fuseau horaire",

        "dxCalendar-todayButtonText": "Aujourd'hui",
        "dxCalendar-ariaWidgetName": "Calendrier",

        "dxColorView-ariaRed": "Rouge",
        "dxColorView-ariaGreen": "Vert",
        "dxColorView-ariaBlue": "Bleu",
        "dxColorView-ariaAlpha": "Transparence",
        "dxColorView-ariaHex": "Code couleur",

        "vizExport-printingButtonText": "Imprimer",
        "vizExport-titleMenuText": "Exporter/imprimer",
        "vizExport-exportButtonText": "{0} fichier(s)"
  }
}
