// This is used in timesheet and should be removed when timesheet used phx-modal
.responsiveModal {
  .modal {
    z-index: 1041;
  }

  .modal-open {
    overflow-y: hidden;
  }

  .modal-dialog.modal-sm,
  &.modal-dialog.modal-sm {
    width: 360px;
    margin: 0 auto;
    overflow-x: hidden;
  }

  .modal-content {

    .modal-body {
      overflow-x: hidden;

      .caption {
        color: #989898;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    .confirm-fab {
      position: absolute;
      bottom: 35px;
      right: 25px;
      padding-left: 12px;
      padding-right: 12px;

      button:hover,
      button:focus,
      a:hover,
      a:focus {
        color: #23527c;
        text-decoration: underline;
      }

      a,
      button {
        color: #337ab7;
        text-decoration: none;
      }

      button {
        padding: 0;
        margin-top: 6px;
        margin-bottom: 6px;
      }

      .material-icons {
        font-size: 56px;
        display: block;
      }
    }
  }

  .ui-select-match .btn-link {
    color: #337ab7;
  }


  @media screen and (max-width: 600px) {

    .modal-dialog,
    &.modal-dialog {

      &.modal-sm,
      &.modal-lg {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
    }

    .confirm-fab {
      right: 40px;
    }
  }
}

.modal-dialog.responsive-modal.phx-modal {
  &.modal-sm {
    width: 360px;
    margin: 0 auto;
    overflow-x: hidden;
  }

  @media screen and (max-width: 767px) {
    .modal-content {
      .modal-body {
        .form-control.custom-form-field {
          border: none !important;
          box-shadow: none !important;
          outline: none !important;
          padding: 0 !important;
          font-size: 1.1em;
        }
      }
    }
  }
}


.responsive-modal, .modal-dialog, .phx-modal {
  .modal-header {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 56px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.09) !important;
    width: 100%;
    h4 {
     display: flex;
     width: 98%;
    }

    .close {
      opacity: 0.8;
      height: 24px;
      flex: 0;
      margin: 0;
      line-height: 32px;
    }

    .back {
      margin-right: 12px;
    }
  }

  .modal-body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  @media screen and (min-width: 768px) {
    .modal-body {
      max-height: var(--modal-body-height-for-medium, 600px);
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    .modal-content {
      height: 100%;
      max-height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: none;
      border-radius: 0;
    }

    .modal-footer {
      height: 70px;
    }
  }
}

.modal-dialog.responsive-modal.phx-full-screen-modal {
  &.responsive-modal {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .modal-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: none;
    border-radius: 0;
  }

  .modal-body {
    max-height: unset;
  }

  .modal-footer {
    height: 70px;
  }
}

/* End responsive modal */

/* Start slide-up-modal */

.slide-up-modal {
  .modal-dialog {
    width: 100%;
    height: 75%;
    position: absolute;
    bottom: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .modal-content {
    border-radius: 0;
    height: auto;
    width: 100%;
    min-height: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .message-header {
    padding-bottom: 20px;
    font-size: 28px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    color: #999999;
  }

  .modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, 25%);
  }

  .modal.fade.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  @media only screen and (max-width: 768px) {
    .modal-content {
      max-width: initial;
    }
  }
}

/* End slide-up-modal */

/* Start favourite asterix checkbox */

input[type=checkbox].favourite-star {
  display: none;
}

/* to hide the checkbox itself */

input[type=checkbox].favourite-star + label:before {
  font-family: 'Material Icons';
  font-size: 24px;
  line-height: 18px;
  display: inline-block;
  content: "\E83A";
}

/* unchecked icon */

input[type=checkbox] + label:before {
  letter-spacing: 0;
}

/* space between checkbox and label */

input[type=checkbox].favourite-star:checked + label:before {
  content: "\E838";
  letter-spacing: 0;
}

/* End favourite asterix checkbox */

.btn.chevron-action {
  width: 100%;
  display: block;
  margin-bottom: 12px;
  padding-right: 0;

  & > .material-icons {
    padding-top: 3px;
    line-height: 14px !important;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  & > .action-label {
    display: inline-block;
    width: calc(~'100% - 24px');
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.expense-detail {
  tabset.mobile ul {
    display: none;
  }
}

.btn-phx {
  margin-top: 5px;
  margin-bottom: 5px;
}

.gridContainer tr.error,
.gridContainer tr.error td {
  color: red !important;
}

.circle {

  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 50%;
  }

  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
  }
}

.cropper-container {
  touch-action: none;
}

.expense-item-modal {
  width: 450px;
}
.workflowhistory-item-modal {
  width: 850px;
}
.ts-split-period-modal {
  width: 800px;
}

dx-tree-view.dx-treeview {
  .dx-treeview-node.dx-state-focused > div.dx-item.dx-treeview-item {
    background-color: #ddd;

    * {
      color: #333;
    }

    .icon-warning {
      color: #d35400;
    }

    .icon-done {
      color: #4cae4c;
    }
  }
}

.portal-container {
  .content {
      padding: 56px 39px;

      .logo {
          margin-bottom: 47px;
      }

      .login-buttons {
          padding-top: 0.5em;

          &:after {
              display: table;
              content: '';
              clear: both;
          }

          .login-action {
              float: right;
          }
          
          .login-action.btn {
              min-width: 96px;
          }
      }

      .login-error {
        margin-top: 20px;
        margin-bottom: 0;
      }

      // Something is wrong with our bootstrap gutters (row margins != col padding). Temp fix only in here
      .form-group.row {
        margin-left: -10px;
        margin-right: -10px;
      }

  }
}

.ats-data-modal-content{
  span.segment-key {
      color: #000 !important;
      font-weight:bold;
  }
  span.segment-separator {
      color: #000 !important;
  }
  span.segment-value{
      color: #000 !important;
      background-color: #fff !important;
  }
}
