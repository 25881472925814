import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import moment from 'moment';
import { StorageService } from 'ngx-webstorage-service';

import { MODULE_ROUTES } from '@common/constants/module-routes.const';

import { PhxConstants } from '../model';
import { ApiService } from '../services/api.service';
import { AppStorageService } from '../services/app-storage.service';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';

import HTTPResponseStatus = PhxConstants.HTTPResponseStatus;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshingInProgress: boolean;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private endpointsToIgnoreAuthError: Array<string> = ['Account/Logout'];
  private refreshTokenErrorKeys: Array<string> = ['invalid_grant', 'invalid_clientId'];

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router,
    private toastService: ToastService,
    @Inject(AppStorageService) private storageService: StorageService
  ) {}

  private static addDefaultHeaders(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return request;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bearerToken = this.storageService.get('BearerToken');
    const bearerTokenExpiresOn = this.storageService.get('BearerTokenExpiresOn');

    const twoMinutesFromNow = moment().add(+2, 'm');
    const getTokenCall = req.url.indexOf('token') !== -1;
    // if bearer token expires in under 2 minutes, refresh the token automatically
    // Being used to guard against token expiry when using the token in decisions to call microservices
    if (!getTokenCall && bearerTokenExpiresOn && moment(bearerTokenExpiresOn) < twoMinutesFromNow) {
      return this.refreshToken(req, next);
    }

    return next.handle(TokenInterceptor.addDefaultHeaders(req, bearerToken)).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          // in case of 401 http error
          if (err.status === HTTPResponseStatus.Unauthorized && !this.endpointsToIgnoreAuthError.some(endpoint => req.url.includes(endpoint))) {
            // get refresh tokens
            const refreshToken = this.storageService.get('RefreshToken');

            // if there are tokens then send refresh token request
            if (refreshToken && bearerToken) {
              return this.refreshToken(req, next);
            }

            // otherwise logout and redirect to login page
            return this.logoutAndRedirect();
          }

          // in following case (refresh token failed)
          if (err.status === HTTPResponseStatus.BadRequest && err.error && this.refreshTokenErrorKeys.includes(err.error.error)) {
            // logout and redirect to login page
            return this.logoutAndRedirect();
          }
        }

        // if error has status neither 401 nor 400 then just return this error
        return throwError(() => err);
      })
    );
  }

  private logoutAndRedirect(): Observable<HttpEvent<never>> {
    this.authService.logout(false);
    // only show the toast message if user is logged in
    // we try to automatically log the person in from home page by using the refresh token to generate new access token
    // if failed to generate access token, user does not need to see the toast message
    if (this.authService.currentUser) {
      this.toastService.logWarning('Your session expired, please login again...');
    }
    this.router.navigate(['/', MODULE_ROUTES.LOGIN]);
    return EMPTY;
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);

      return this.apiService.refreshToken().pipe(
        switchMap(res => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(res.access_token);
          // repeat failed request with new token
          return next.handle(TokenInterceptor.addDefaultHeaders(request, res.access_token));
        }),
        catchError(err => {
          this.refreshingInProgress = false;
          return throwError(() => err);
        })
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          // repeat failed request with new token
          return next.handle(TokenInterceptor.addDefaultHeaders(request, token));
        })
      );
    }
  }
}
